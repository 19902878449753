<template>
    <b-container>
        <component :is="componentInstance"/>
    </b-container>
</template>

<script>
    export default {
        name: "BContainerWrapper",
        computed: {
            componentInstance() {
                return this.$route.meta.componentInstance;
            }
        }
    }
</script>
